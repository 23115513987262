import React, {useState, useEffect} from "react"

import { Container, Title, Grid, Img } from "./styles"

function Gallery() {
  const [images, setImages] = useState([]);

  useEffect(function(){
    async function getData(){
      let response = await fetch('https://healingbyeve.unnitec.com.br/api/v1/images')

      const json  = await response.json();

      setImages(json.data);
    }

    getData();
  }, [])

  return (
    <div className="container" id="gallery">
      <Container>
        <Title>My Gallery</Title>
        <Grid>
        {images.map((image, index) =>   <Img
          key={index}
          src={image.image_url}
          alt={`Imagem ${index + 1} da Galeria`}
        />)}
        </Grid>
      </Container>
    </div>
  )
}

export default Gallery
