import styled from "styled-components"
import { colors } from "../../styles"

export const Container = styled.div`
  background: #f2f5ef;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8rem;
`

export const Circle = styled.div`
  background: ${colors.white};
  border-radius: 100%;
  margin-top: -10px;
  padding: 15px;
  width: 800px;
  height: 600px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767.98px) {
    height: 400px;
  }

  @media (max-width: 575.98px) {
    height: 500px;
  }
`

export const Info = styled.div`
  align-self: center;
  margin-left: 3rem;
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 160%;
  color: ${colors.primary};
`

export const Text = styled.p`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 160%;
`

export const Image = styled.img`
  height: 70%;
  margin-top: -5rem;
  border-radius: 0px 70px 70px 70px;

  @media (max-width: 767.98px) {
    display: none;
  }
`
