import styled from "styled-components"
import { FiCheckCircle } from "react-icons/fi"

import { colors } from "../../styles"

export const Container = styled.div`
  margin-top: 15rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10rem;

  @media (max-width: 991.98px) {
    margin-top: 1rem;
    display: block;
    width: 100%;
  }
`

export const ContainerImage = styled.div`
  position: relative;

  @media (max-width: 991.98px) {
    display: none;
  }

  &::after {
    position: absolute;
    content: "";
    bottom: -1%;
    right: -3%;
    width: 100%;
    height: 100%;
    border: 1px solid ${colors.primary};
    border-radius: 48px;
    z-index: -1;
  }
`

export const Image = styled.img`
  border-radius: 48px;
  max-width: 100%;
  height: auto;
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 3rem;
  line-height: 160%;
  color: ${colors.black};
`

export const SubTitle = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 160%;
  margin-bottom: 1rem;
`

export const Service = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

export const ServiceIcon = styled(FiCheckCircle)`
  margin-right: 0.5rem;
  color: ${colors.primary};
  width: 20px;
  height: 20px;
`

export const ServiceText = styled.p`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 160%;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &:first-child {
    grid-column: 1 / -1;
  }
`
