import React from "react"
import {
  FiMail,
  FiInstagram,
  FiFacebook,
  FiPhone,
  FiMapPin,
} from "react-icons/fi"
import { Row, ContainerImage, Image, Title, Item, Text, Info } from "./styles"

import contactImage from "../../images/contact.png"

function Contact({information}) {
  return (
    <div className="container" id="contact">
      <Row>
        <ContainerImage>
          <Image src={contactImage} alt="Contact Image" />
        </ContainerImage>
        <Info>
          <Title>Contact me</Title>
          <Item>
            <FiMail />
            <Text>{information && information.email}</Text>
          </Item>
          <Item>
            <FiInstagram />
            <Text>{information && information.instagram}</Text>
          </Item>
          <Item>
            <FiFacebook />
            <Text>{information && information.facebook}</Text>
          </Item>
          <Item>
            <FiPhone />
            <Text>{information && information.phone}</Text>
          </Item>
          <Item>
            <FiMapPin />
            <Text>{information && information.address}</Text>
          </Item>
        </Info>
      </Row>
    </div>
  )
}

export default Contact
