import React, {useState, useEffect} from "react"
import About from "../../components/About"
import Contact from "../../components/Contact"
import Gallery from "../../components/Gallery"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Services from "../../components/Services"

export default function HomePage() {

  const [information, setInformation] = useState(null);

  useEffect(function(){
    async function getData(){
      let response = await fetch('https://healingbyeve.unnitec.com.br/api/v1/information')

      const json  = await response.json();

      setInformation(json.data);
    }

    getData();
  }, [])
  return (
    <Layout information={information}>
      <SEO />
      <Services information={information} />
      <About information={information} />
      <Gallery />
      <Contact information={information} />
    </Layout>
  )
}
