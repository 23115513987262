import styled from "styled-components"
import { colors } from "../../styles"

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
`

export const ContainerImage = styled.div`
  flex: 0 0 auto;
  width: 50%;

  @media (max-width: 991.98px) {
    display: none;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`

export const Info = styled.div`
  margin-left: 2rem;

  @media (max-width: 991.98px) {
    margin-left: 0;
  }
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 160%;
  color: ${colors.primary};
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  svg {
    font-size: 1.25rem;
  }
`

export const Text = styled.p`
  margin-left: 1rem;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 160%;
`
