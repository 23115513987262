import React from "react"

import {
  Container,
  Image,
  Title,
  SubTitle,
  Service,
  ServiceIcon,
  ServiceText,
  ContainerImage,
  Grid,
} from "./styles"

import imageMassage from "../../images/image-3.png"

function Services({information}) {
  const services = information ? JSON.parse(information.services) : [];

  return (
    <div className="container" id="services">
      <Container>
        <ContainerImage>
          <Image src={imageMassage} alt="Image massage white woman" />
        </ContainerImage>
        <div>
          <Title>Healing by Eve</Title>
          <SubTitle>About my healing nurturing treatments:</SubTitle>
          <Grid>
            {services.map((service, index) => (
              <Service
                key={index}
                style={index < 2 ? { gridColumn: "1 / -1" } : {}}
              >
                <ServiceIcon />
                <ServiceText>{service}</ServiceText>
              </Service>
            ))}
          </Grid>
        </div>
      </Container>
    </div>
  )
}

export default Services
