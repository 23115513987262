import styled from "styled-components"
import { colors } from "../../styles"

export const Container = styled.div`
  margin: 2rem 0;
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 160%;
  color: ${colors.primary};
  text-align: center;
  margin-bottom: 2rem;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 20vw);
  grid-gap: 15px;

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
