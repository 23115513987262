import React from "react"

import { Container, Circle, Title, Text, Image, Info } from "./styles"

function About({information}) {
  return (
    <Container id="about">
      <Circle>
        <Info>
          <Title>About me</Title>
          <Text>
          {information && information.about}
          </Text>
        </Info>
        {information && <Image src={information.image_url} alt="Eve" />}
      </Circle>
    </Container>
  )
}

export default About
